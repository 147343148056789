/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/bootstrap@4.5.2/dist/js/bootstrap.bundle.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/plyr@3.6.2/dist/plyr.min.js
 * - /npm/swiper@6.0.2/swiper-bundle.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
